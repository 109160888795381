<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
    <tr>
      <slot name="columns">

        <th  v-for="column in columns" :key="column">
          <input v-model="selectAll" style="width: 15px;" v-if="column == 'select'" class="form-control" type="checkbox" />
          <span v-else>{{column}}</span>
        </th>
      </slot>
    </tr>
    </thead>
    <tbody :class="tbodyClasses">
    <tr v-for="(item, index) in showedData" :key="index">
      <slot :row="item">
        <td
          v-for="(column, index) in columns"
          :key="index"
          v-if="column == 'select'"
        >
          <input v-model="selected[item.id]" type="checkbox" />
        </td>

        <td v-for="(column, index) in columns"
            :key="index"
            v-if="hasValue(item, column) && !['control', 'select'].includes(column)">
            <span v-if="column === 'Date'">{{ formatDate(item.createdAt) }}</span>
            <span v-if="column === 'callback status'">{{ item.delivered ? "success" : "failed" }}</span>
            <span v-else>
              {{itemValue(item, column)}}
            </span>
          
        </td>

        <td
          v-for="(column, index) in columns"
          :key="index"
          v-if="column == 'control'"
        >
          <base-button v-if="!item.delivered" @click="sendCallback(item._id)" size="sm"  :type="'primary'" fill>Send Callback</base-button>
        </td>
      </slot>
    </tr>
    </tbody>
  </table>
  </div>

</template>
<script>
  import { calculateDataToShowRanges } from '../services/utils';
import BaseButton from './BaseButton.vue';
  import moment from 'moment'
  export default {
    name: 'offer-table',
    components: {
      BaseButton,
    },
    data() {
      return {
        selectAll: false,
        selected: {}
      }
    },
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      },
      sendCallback: {
        type: Function
      },
      perPage: {type: Number},
      currentPage: {type: Number},
      onSelect: {
        type: Function
      },
      selectedItems: {
        type: Array
      }
    },
    updated() {

    },
    mounted() {

    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      },
      showedData() {
        const data = calculateDataToShowRanges(this.currentPage, this.perPage, this.data);
        return data;
      },
      totalPages() {
        return this.data.length / this.perPage
      }
    },
    methods: {
      hasValue(item, column) {
        return item[column.toLowerCase()] !== "undefined";
      },
      itemValue(item, column) {
        return item[column.toLowerCase()];
      },
      formatDate(date) {
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    watch: {
      currentPage() {
        // console.log('----currentPage changed', this.currentPage);

      },
      selectAll(value) {
        // console.log('--selectAll changed', value);
        this.onSelect(this.showedData.map(x => x.id), value)
      },
      selectedItems(value) {
        // console.log('--selectedItems changeed', value);
        const obj = {}
        for (let i = 0; i < value.length; i++) {
          obj[value[i]] = true
        }
        // console.log('-----obj', obj);
        if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
          this.selected = {...obj};
        }
      },
      selected(value) {
        const ids = Object.keys(value);
        this.onSelect(ids, value)
      }
    }
  };
</script>
<style>
</style>

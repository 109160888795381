var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table tablesorter",class:_vm.tableClass},[_c('thead',{class:_vm.theadClasses},[_c('tr',[_vm._t("columns",function(){return _vm._l((_vm.columns),function(column){return _c('th',{key:column,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setSortBy(column)}}},[_c('div',{staticClass:"row align-items-center ml-0"},[_c('span',[_vm._v(_vm._s(column))]),(_vm.sortBy == _vm.getSortByName(column))?_c('i',{staticClass:"tim-icons ml-2",class:{
                  'icon-minimal-down': _vm.sort == -1,
                  'icon-minimal-up': _vm.sort == 1,
                }}):_vm._e()])])})})],2)]),_c('tbody',{class:_vm.tbodyClasses},_vm._l((_vm.showedData),function(item,index){return (!_vm.loading)?_c('tr',{key:index},[_vm._t("default",function(){return [_vm._l((_vm.columns),function(column,index){return (column == 'select')?_c('td',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected[item.id]),expression:"selected[item.id]"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selected[item.id])?_vm._i(_vm.selected[item.id],null)>-1:(_vm.selected[item.id])},on:{"change":function($event){var $$a=_vm.selected[item.id],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.selected, item.id, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.selected, item.id, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.selected, item.id, $$c)}}}})]):_vm._e()}),_vm._l((_vm.columns),function(column,index){return (
              _vm.hasValue(item, column) &&
              !['control', 'select'].includes(column)
            )?_c('td',{key:index},[(column === 'Status')?_c('span',[_vm._v(_vm._s(item.blocked ? "Blocked" : "Active"))]):_vm._e(),(column === 'Total Clicks')?_c('span',[_vm._v(_vm._s(item.clicks))]):_vm._e(),(column === 'Total Conversions')?_c('span',[_vm._v(_vm._s(item.conversions || 0))]):_vm._e(),(column === 'User ID')?_c('span',[_vm._v(_vm._s(item.user_id))]):_c('span',[_vm._v(" "+_vm._s(_vm.itemValue(item, column))+" ")])]):_vm._e()}),_vm._l((_vm.columns),function(column,index){return (column == 'control')?_c('td',{key:index},[(!item.delivered)?_c('base-button',{staticClass:"mr-2",attrs:{"size":"sm","type":'primary',"fill":""},on:{"click":function($event){return _vm.updateUser(item.blocked, item._id)}}},[(_vm.loadings?.['blocked'] == item._id)?_c('clip-loader',{attrs:{"color":"#fff","size":"15px","loading":true}}):_c('span',{staticStyle:{"margin-left":"0px !important"}},[_vm._v(" "+_vm._s(item.blocked ? "Unblock" : "Block"))])],1):_vm._e(),_c('base-button',{attrs:{"size":"sm","type":'primary',"fill":""},on:{"click":function($event){return _vm.openUser(item.user_id)}}},[_vm._v(" Details")])],1):_vm._e()})]},{"row":item})],2):_vm._e()}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
        <tr>
          <slot name="columns">
            <th
              v-for="column in columns"
              :key="column"
              @click="setSortBy(column)"
              style="cursor: pointer"
            >
              <!-- <input v-model="selectAll" style="width: 15px;" v-if="column == 'select'" class="form-control" type="checkbox" /> -->
              <div class="row align-items-center ml-0">
                <span>{{ column }}</span>
                <i
                  v-if="sortBy == getSortByName(column)"
                  class="tim-icons ml-2"
                  :class="{
                    'icon-minimal-down': sort == -1,
                    'icon-minimal-up': sort == 1,
                  }"
                ></i>
              </div>
            </th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-if="!loading" v-for="(item, index) in showedData" :key="index">
          <slot :row="item">
            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="column == 'select'"
            >
              <input v-model="selected[item.id]" type="checkbox" />
            </td>

            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="
                hasValue(item, column) &&
                !['control', 'select'].includes(column)
              "
            >
              <span v-if="column === 'Status'">{{
                item.blocked ? "Blocked" : "Active"
              }}</span>
              <span v-if="column === 'Total Clicks'">{{ item.clicks }}</span>
              <span v-if="column === 'Total Conversions'">{{
                item.conversions || 0
              }}</span>
              <span v-if="column === 'User ID'">{{ item.user_id }}</span>
              <span v-else>
                {{ itemValue(item, column) }}
              </span>
            </td>

            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="column == 'control'"
            >
              <base-button
                class="mr-2"
                v-if="!item.delivered"
                @click="updateUser(item.blocked, item._id)"
                size="sm"
                :type="'primary'"
                fill
              >
                <clip-loader
                  v-if="loadings?.['blocked'] == item._id"
                  color="#fff"
                  size="15px"
                  :loading="true"
                ></clip-loader>

                <span style="margin-left: 0px !important" v-else>
                  {{ item.blocked ? "Unblock" : "Block" }}</span
                >
              </base-button>
              <base-button
                size="sm"
                @click="openUser(item.user_id)"
                :type="'primary'"
                fill
              >
                Details</base-button
              >
            </td>
          </slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { calculateDataToShowRanges } from "../services/utils";
import BaseButton from "./BaseButton.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "offer-table",
  components: {
    BaseButton,
    ClipLoader
  },
  data() {
    return {
      selectAll: false,
      selected: {},
      sortBy: "_id",
      sort: 1,
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    updateUser: {
      type: Function,
    },
    perPage: { type: Number },
    currentPage: { type: Number },
    onSelect: {
      type: Function,
    },
    selectedItems: {
      type: Array,
    },
    loading: Boolean,
    loadings: Object,
  },
  updated() {},
  mounted() {},
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    // showedData() {
    //   return this.data.slice(this.currentPage == 1 ? 0 : this.currentPage * this.perPage, (this.currentPage * this.perPage) + this.perPage)
    // },
    showedData() {
      // console.log('---------showedData', this.currentPage == 1 ? 0 : this.currentPage * this.perPage, (this.currentPage * this.perPage) + this.perPage);
      const { sort, sortBy } = this;
      // console.log('---sort', sort, sortBy);
      let data = this.data;
      if (sortBy) {
        data = data.sort((a, b) => {
          // console.log(isNaN(a?.[sortBy]), a[sortBy].localeCompare);
          if (sort == 1) {
            return isNaN(a?.[sortBy]) &&
              a[sortBy]?.localeCompare &&
              b[sortBy]?.localeCompare
              ? a[sortBy]?.localeCompare(b[sortBy])
              : a?.[sortBy] - b?.[sortBy];
          } else {
            return isNaN(a?.[sortBy]) &&
              a[sortBy]?.localeCompare &&
              b[sortBy]?.localeCompare
              ? b[sortBy]?.localeCompare(a[sortBy])
              : b?.[sortBy] - a?.[sortBy];
          }
        });
      }

      // console.log('----start ', {start, end});
      data = calculateDataToShowRanges(this.currentPage, this.perPage, data);

      return data;
    },
    totalPages() {
      return this.data.length / this.perPage;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column?.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column?.toLowerCase()];
    },
    setSortBy(column) {
      console.log("---clicked", column);
      this.sortBy = this.getSortByName(column);
      this.sort = !this.sort || this.sort == -1 ? 1 : -1;
      console.log("-----this.sortBy", this.sortBy, this.sort);
    },
    getSortByName(column) {
      console.log("---column", column);
      if (typeof column == "string") {
        switch (column) {
          case "Status":
            return "blocked";
          case "Total Clicks":
            return "clicks";
          case "Total Conversions":
            return "conversions";
          case "User ID":
            return "user_id";
          default:
            return column?.toLowerCase();
        }
      }
    },
    openUser(id) {
      this.$router.replace(`/users/${id}`);
    },
  },
  watch: {
    currentPage() {
      console.log("----currentPage changed", this.currentPage);
    },
    selectAll(value) {
      console.log("--selectAll changed", value);
      this.onSelect(
        this.showedData.map((x) => x.id),
        value
      );
    },
    selectedItems(value) {
      console.log("--selectedItems changeed", value);
      const obj = {};
      for (let i = 0; i < value.length; i++) {
        obj[value[i]] = true;
      }
      console.log("-----obj", obj);
      if (JSON.stringify(obj) !== JSON.stringify(this.selected)) {
        this.selected = { ...obj };
      }
    },
    selected(value) {
      const ids = Object.keys(value);
      this.onSelect(ids, value);
    },
    loadings(val) {
      console.log('[----loadings ch;anged]', val);
    }
  },
};
</script>
<style></style>

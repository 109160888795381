<template>
  <div class="content">
    <div>
      <base-alert v-for="alert in alerts" :type="alert.type" dismissible >
          <span><b> {{ alert.message }}</b></span>
        </base-alert>
    </div>
    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </fade-transition>
  </div>
</template>
<script>
import { BaseAlert } from "@/components";
import { mapGetters } from "vuex";

  import {FadeTransition} from 'vue2-transitions';
  export default {
    components: {
      FadeTransition,
      BaseAlert
    },
    computed: {
      ...mapGetters([
        "alerts"
      ])
    },
  };
</script>
